.custom-scrollbar::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e5e5e5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #cbcbcb;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #cbcbcb;
}
