.Toastify__toast--error {
  background-color: #e53935 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #7cb342 !important;
}

.Toastify__toast-icon {
  margin-top: 4px !important;
}

.Toastify__toast-body {
  align-items: start !important;
}
